import LoginCore from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/login-core/1.0.0/index.vue';
import UploadImg from '@/page/admin/company-management/staff-management/component/uploadImg/index'
import SelectCity from '@/page/admin/company-management/staff-management/component/selectCity/index'
import selectIndustry from "@/lib/data-service/haolv-default/web_industry_selectIndustry";
import consumer_web_company_detail from '@/lib/data-service/haolv-default/consumer_web_company_detail'
import consumer_web_company_companyRegiste2 from '@/lib/data-service/haolv-default/consumer_web_company_companyRegiste2'
import consumer_user_logout from 'yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_user_logout'

//TODO:处理登录

export default {
    components: {
        LoginCore,
        UploadImg,
        SelectCity
    },
    data() {
        const validatePhone = (rule, value, callback) => {
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback(new Error('不符合手机号码格式'))
            }
        }
        const validateStaffName = (rule, value, callback) => {
            const regExp = /^[\u0391-\uFFE5A-Za-z]+$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback('只能输入中文、英文')
            }
        }
        const validateCompanyName = (rule, value, callback) => {
            const regExp = /[\u2E80-\u9FFFa-zA-Z0-9].{1,30}/
            if (!regExp.test(value)) {
                callback('不符合公司名格式')
            } else {
                callback()
            }
        }
        const checkBusinessLicense = (rule, value, callback) => {
            const reg = /^([159Y]{1})([1239]{1})([0-9ABCDEFGHJKLMNPQRTUWXY]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-9ABCDEFGHJKLMNPQRTUWXY])$/;
            if (!value) {
                return callback(new Error("请输入营业执照"));
            } else if (!reg.test(value)) {
                callback(new Error("请输入正确的营业执照"));
            } else {
                callback();
            }
        }
        return {
            loading: '',
            companyId: '',

            registerParams: {
                abbreviation: '',
                industryId: '',
                scale: '',
                pid: '',
                cid: '',
                rid: '',
                companyProfile: '',
                logo: '',
                businessLicenseNum: '',
                businessLicensePic: '',
                contractLinkman: {
                    name: '',
                    phone: ''
                },
                financeLinkman: {
                    name: '',
                    phone: ''
                },
                reserveLinkman: {
                    name: '',
                    phone: ''
                },
                urgencyLinkman: {
                    name: '',
                    phone: ''
                },
                otherFiles: '',
                companyId: '',
                industryName: '',
                cname: '',
                pname: '',
                rname: '',

                initCity: {}
            },
            registerRule: {
                abbreviation: [
                    { required: true, message: '请输入公司简称', trigger: 'blur' }
                ],
                industryId: [
                    { required: true, message: '请选择所属行业', trigger: 'change' }
                ],
                scale: [
                    { required: true, message: '请选择企业规模', trigger: 'change' }
                ],
                rid: [
                    { required: true, message: '请选择所在城市', trigger: 'change' }
                ],
                companyProfile: [],
                logo: [
                    { required: true, message: '请上传logo', trigger: 'change' }
                ],
                businessLicenseNum: [
                    { required: true, message: '请输入营业执照', trigger: 'blur' },
                    { validator: checkBusinessLicense, trigger: 'blur' }
                ],
                businessLicensePic: [
                    { required: true, message: '请上传营业执照', trigger: 'change' }
                ],
                'contractLinkman.name': [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                'contractLinkman.phone': [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'},
                    // { validator: validatePhone, trigger: 'blur' }
                ],
                'financeLinkman.name': [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                'financeLinkman.phone': [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'},
                    // { validator: validatePhone, trigger: 'blur' }
                ],
                'reserveLinkman.name': [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                'reserveLinkman.phone': [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'},
                    // { validator: validatePhone, trigger: 'blur' }
                ],
                'urgencyLinkman.name': [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                'urgencyLinkman.phone': [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'},
                    // { validator: validatePhone, trigger: 'blur' }
                ]
            },
            industryList: [],
            scaleList: [
                {value: 1, label: '0~20人'},
                {value: 2, label: '20~50人'},
                {value: 3, label: '50~100人'},
                {value: 4, label: '100~300人'},
                {value: 5, label: '300~1000人'},
                {value: 6, label: '1000人以上'},
            ],
            registerForm: ['companyForm', 'dataForm', 'contactForm'],
            companyName: '',
            disabled: true,
            register: false, // 是否是从注册进来
            initStatus: false,
        };
    },
    methods: {
        getIndustryList() {
            selectIndustry().then(res => {
                this.industryList = res.datas;
            });
        },
        changeIndustry(val) {
            for (let i = 0, l = this.industryList.length; i < l; i++) {
                if (this.industryList[i].id === val) {
                    this.registerParams.industryName = this.industryList[i].industryName
                    break
                }
            }
        },
        uploadImgUrl(val) {
            this.registerParams.logo = val
            this.$message({
                message: '上传成功',
                type: 'success'
            })
        },
        delImgUrl() {
            this.registerParams.logo = ''
        },
        uploadBusinessImgUrl(val) {
            this.registerParams.businessLicensePic = val
            this.$message({
                message: '上传成功',
                type: 'success'
            })
        },
        delBusinessImgUrl() {
            this.registerParams.businessLicensePic = ''
        },
        getCityInfo(val) {
            this.registerParams.pid = val.province.id
            this.registerParams.cid = val.city.id
            this.registerParams.rid = val.region.id
            this.registerParams.pname = val.province.name
            this.registerParams.cname = val.city.name
            this.registerParams.rname = val.region.name
        },
        dataSubmit() {
            let formStatus = [-1, -1, -1]
            this.registerForm.map((value, index) => {
                this.$refs[value].validate((valid) => {
                    valid ? formStatus[index] = 1 : formStatus[index] = -1
                    if (formStatus.indexOf(-1) === -1) {
                        this.loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                        })
                        let registerParams = Object.assign({}, this.registerParams)
                        registerParams.contractLinkman = JSON.stringify(registerParams.contractLinkman)
                        registerParams.financeLinkman = JSON.stringify(registerParams.financeLinkman)
                        registerParams.reserveLinkman = JSON.stringify(registerParams.reserveLinkman)
                        registerParams.urgencyLinkman = JSON.stringify(registerParams.urgencyLinkman)
                        consumer_web_company_companyRegiste2(registerParams).then(res => {
                            this.loading.close()
                            /*this.$message({
                                message: '注册成功',
                                type: 'success'
                            })*/
                            this.disabled = true
                            this.initStatus = true
                            // 组装当前页面url
                            let href = location.href.split('?')[0]
                            let newUrl = `${href}?companyId=${this.registerParams.companyId}&disabled=1`
                            history.replaceState(null, null, newUrl)
                            this.$alert(`${this.registerParams.companyName}，您的账号正在审核中，请耐心等待`, '温馨提示', {
                                confirmButtonText: '确定',
                                callback: action => {
                                    if (this.register) {
                                        this.$router.push({
                                            name: 'login'
                                        })
                                    } else {
                                        this.backLogin()
                                    }
                                }
                            })
                            /*this.$router.push({
                                name: 'login'
                            })*/
                            /*this.$router.replace({
                                name: `register`,
                                query: {
                                    companyId: this.registerParams.companyId,
                                    disabled: 1
                                }
                            });*/
                        }).catch(() => {
                            this.loading.close()
                        })
                    }
                })
            })
        },
        dataCancel() {
            // 组装当前页面url
            // let href = location.href.split('?')[0]
            // let newUrl = `${href}`
            // history.replaceState(null, null, newUrl)
            if (this.disabled) {
                this.backLogin()
            } else {
                this.$router.push({
                    name: 'login'
                })
            }
        },
        backLogin() {
            consumer_user_logout().then(() => {
                this.$router.push({
                    name: 'login'
                })
            })
        },
    },
    created() {
        // const __this = this;
        this.companyId = parseInt(this.$route.query.companyId)
        this.registerParams.companyId = this.companyId
        let disabled = this.$route.query.disabled ? parseInt(this.$route.query.disabled) : 0 // 1为禁用 0为启用
        let register = this.$route.query.register ? parseInt(this.$route.query.register) : 0
        this.disabled = disabled === 1 ? true : false
        this.register = register === 1 ? true : false
        if (!this.register) {
            consumer_web_company_detail({id: this.companyId}).then(res => {
                let registerParams = res.datas
                this.registerParams = {
                    companyName: registerParams.companyName,
                    abbreviation: registerParams.abbreviation,
                    industryId: registerParams.industryId,
                    scale: registerParams.scale,
                    pid: registerParams.areaProvince,
                    cid: registerParams.areaCity,
                    rid: registerParams.areaRegion,
                    companyProfile: registerParams.companyIntroduce,
                    logo: registerParams.companyLogo,
                    businessLicenseNum: registerParams.businessLicenseNum,
                    businessLicensePic: registerParams.businessLicensePic,
                    contractLinkman: registerParams.contractLinkman ? JSON.parse(registerParams.contractLinkman) : {name: '', phone: ''},
                    financeLinkman: registerParams.financeLinkman ? JSON.parse(registerParams.financeLinkman) : {name: '', phone: ''},
                    reserveLinkman: registerParams.reserveLinkman ? JSON.parse(registerParams.reserveLinkman) : {name: '', phone: ''},
                    urgencyLinkman: registerParams.urgencyLinkman ? JSON.parse(registerParams.urgencyLinkman) : {name: '', phone: ''},
                    otherFiles: '',
                    companyId: this.companyId,
                    industryName: registerParams.industryName,
                    cname: registerParams.areaCityName,
                    pname: registerParams.areaProvinceName,
                    rname: registerParams.areaRegionName,
                    initCity: {
                        provinceName: registerParams.areaProvinceName,
                        cityName: registerParams.areaCityName,
                        regionName: registerParams.areaRegionName
                    }
                }
                this.$nextTick(() => {
                    this.$refs['companyForm'].clearValidate()
                })
                if (this.disabled) {
                    this.initStatus = true
                }
            }).catch(() => {
                this.dataCancel()
            })
        } else {
            this.disabled = false
            this.registerParams.companyName = this.$route.query.companyName
        }

    },
    mounted() {
        this.getIndustryList()
    },
    activated() {},
    deactivated() {},
}
