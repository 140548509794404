//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
export default {
    data() {
        return {
            imageUrl: "",
            ossClient: new OssClient()
        };
    },
    mounted() {
        this.imageUrl = this.initImg;
    },
    watch: {
        initStatus(val) {
            this.initImg ? (this.imageUrl = this.initImg) : "";
        }
    },
    props: {
        text: {
            type: String,
            default: '上传LOGO'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        initStatus: Boolean, //默认状态
        initImg: String //默认img
    },
    methods: {
        uploadChange(file) {
            this.ossClient
                .multipartUpload({
                    file: file.file
                })
                .then(res => {
                    this.imageUrl = res.url;
                    this.$emit("imgUrl", res.url);
                });
        },
        handleRemoveImg() {},
        clickBlank() {

        },
        delImage() {
            if (this.disabled) {
                return
            }
            this.imageUrl = '';
            this.$emit("delImgUrl");
        },
        detectionImg(file) {
            const reg2 = /^(\s|\S)+(jpg|png|jpeg)+$/;
            const isLt2M = file.size / 1024 < 1024 * 10;
            if (!reg2.test(file.name)) {
                this.$message({ type: "warning", message: "图片格式上传错误！" });
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过10MB!");
                return false;
            }
        }
    }
};
